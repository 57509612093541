
export default {
  name: "Loader",
  data() {
    return {
      showPage: false,
      loadingProgress: 0,
    }
  },
  computed: {
    loadingModelProgress() {
      return this.$store.state.loadingModelProgress
    },
    loadingModelFinished() {
      return this.$store.state.loadingModelFinished
    },
  },
  watch: {
    loadingModelProgress(newVal) {
      if (newVal) {
        this.simulateLoadingProgress(newVal);
      }
    }
  },
  mounted() {
    if (this.$route.path !== '/') {
      this.simulateLoadingProgress(100);
    }
  },
  methods: {
    triggerAnimationSequence() {
      const counter = this.$refs['counter'];
      this.$gsap.to(counter, {
        duration: 1,
        opacity: 0,
        // eslint-disable-next-line
        ease: "back.out(1.7)", // Specify an ease
        onComplete: () => {
          this.showPage = true;
        }
      });
    },
    simulateLoadingProgress(target) {
      if (this.loadingProgress < target && this.loadingProgress < 100) {
        this.loadingProgress += 1;

        setTimeout(() => {
          this.simulateLoadingProgress(target);
        }, 10); // adjust time as needed
      } else if (this.loadingProgress >= 100) {
        this.triggerAnimationSequence();
      }
    },
  },
};
