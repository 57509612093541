import en from './static/lang/en'
import it from './static/lang/it'
import bg from './static/lang/bg'

// default language
const defaultLocale = 'en'

// List of available language
const locales = [
  {
    code: 'en',
    iso: 'en-US',
    name: 'English'
    // isCatchallLocale: true
  },
  {
    code: 'bg',
    iso: 'bg-BG',
    name: 'Bulgaria'
    // isCatchallLocale: true
  },
  // {
  //   code: 'it',
  //   iso: 'it-IT',
  //   name: 'Italy'
  //   // isCatchallLocale: true
  // },
]

// List of available message languages
const i18nLocales = {
  fallbackLocale: 'en',
  messages: { en, bg, it }
}

// List of available message languages
const i18nPages = {
    // 'blog/_slug': {
    //   en: '/blog/:slug',
    //   bg: '/blogec/:slug',
    //   it: '/blog/:slug',
    // },
  }

export { defaultLocale, locales, i18nLocales, i18nPages }
