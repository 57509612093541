// plugins/swiper.js
import Vue from 'vue';
import Swiper from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';

const swiper = {
    install(Vue, options) {
        Vue.prototype.$swiper = Swiper;
    }
};

Vue.use(swiper);
