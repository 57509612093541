import { render, staticRenderFns } from "./default.vue?vue&type=template&id=cb023906&"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loader: require('/codebuild/output/src1293878547/src/biennale/components/global/Loader.vue').default,Menu: require('/codebuild/output/src1293878547/src/biennale/components/global/Menu.vue').default})
