
import FacebookIcon from "~/static/icons/FRANK_Icons_FB.svg?inline";
import MailIcon from "~/static/icons/FRANK_Icons_MAIL.svg?inline";
import InstagramIcon from "~/static/icons/FRANK_Icons_IG.svg?inline";
import CatalogueIcon from "~/static/icons/Catalogue.svg?inline";

export default {
  name: "Menu",
  components: {FacebookIcon, InstagramIcon, MailIcon, CatalogueIcon},
  data() {
    return {
      tlMenuOverlay: null,
      tlMenuLinks: null,
      tlColorChange: null,
      toggleMobileMenu: false,
      pages: {
        data: [
          {id: 1, attributes: {name: 'home', href: '/'}},
          {id: 2, attributes: {name: 'concept', href: '/concept'}},
          {id: 3, attributes: {name: 'photographer', href: '/photographer'}},
          // {id: 4, attributes: {name: 'timeline', href: '/timeline'}},
          {id: 6, attributes: {name: 'scenography', href: '/scenography'}},
          {id: 7, attributes: {name: 'team', href: '/team'}},
          {id: 8, attributes: {name: 'partners', href: '/partners'}},
          {id: 9, attributes: {name: 'resources', href: '/resources'}},
          // {id: 5, attributes: {name: 'gallery', href: '/gallery'}},
        ],
      },
      year: null
    };
  },
  mounted() {
    const now = new Date()
    this.year = now.getFullYear()
    this.menuOverlayAnimation();
    this.menuLinksAnimation();
  },
  methods: {
    triggerMobileNavAnimation() {
      this.toggleMobileMenu = !this.toggleMobileMenu;
      if (this.toggleMobileMenu) {
        document.body.classList.add('is-nav-open');
        this.$refs['headerContainer'].classList.add('text-body-black');

        this.tlMenuOverlay.play().then(() => {
          this.tlMenuLinks.play();
        });
      } else {
        this.tlMenuOverlay.reverse().then(() => {
          this.$refs['headerContainer'].classList.remove('text-body-black');
          this.tlMenuLinks.reverse();
        });
        document.body.classList.remove('is-nav-open');
      }
    },
    menuOverlayAnimation() {
      this.tlMenuOverlay = this.$gsap.timeline({paused: true});

      this.tlMenuOverlay.from(this.$refs['menuOverlay'], {
        yPercent: -100,
        duration: 0.5,
        ease: "power3.inOut",
      });
    },
    menuLinksAnimation() {
      this.tlMenuLinks = this.$gsap.timeline({paused: true});

      this.tlMenuLinks.from('.menu-link', {
        yPercent: 120,
        duration: 0.5,
        ease: "power3.inOut",
        stagger: -0.1
      });
    },
    outsideClickHandler() {
      if (this.toggleMobileMenu) {
        this.triggerMobileNavAnimation();
      }
    }
  }
}
