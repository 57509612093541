import { format } from 'date-fns';
import { enUS, bg, it } from 'date-fns/locale';

export default (context, inject) => {
  const formatDate = (date) => {
    const formatStr = 'dd.MMMM'
    const locale = getLocaleObject(context.app.i18n.locale)
    return format(new Date(date), formatStr, {locale})
  }

  const getLocaleObject = (localeStr) => {
    switch (localeStr) {
      case 'en':
        return enUS;
      case 'bg':
        return bg;
        case 'it':
        return it;
      default:
        return enUS;
    }
  }

  // Inject formatDate into the context so it's available globally.
  inject('formatDate', formatDate);
}
