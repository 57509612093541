import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import schema from '../fragmentTypes.json'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: schema
})

export default function (context) {
  return {
    httpEndpoint: `${context.$config.backendUrl}/graphql`,
    cache: new InMemoryCache({ fragmentMatcher })
  }
}
