export const state = () => ({
  loadingModelProgress: 0,
  previewMode: false,
  isLocomotiveScrollInitialized: false,
})

export const mutations = {
  setLoadingModelProgress(state, value) {
    state.loadingModelProgress = value;
  },
  setPreviewMode(state, value) {
    state.previewMode = value;
  },
  setLocomotiveScrollInitialized(state, value) {
    state.isLocomotiveScrollInitialized = value;
  },
}
