import Vue from 'vue';
import LocomotiveScroll from 'locomotive-scroll';

Vue.prototype.$initLocomotiveScroll = (container) => {
  return new LocomotiveScroll({
    el: container,
    smooth: true,
    getDirection: true,
    scrollFromAnywhere: true,
    multiplier: 0.7,
  });
};
